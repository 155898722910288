import { React, useState } from "react";
import ImageGallery from "react-image-gallery";
import { Modal, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const BannerPropiedad = ({ propiedad, ruta }) => {

    const { t } = useTranslation();

    const [fullscreen, setFullscreen] = useState(true);

    const fotos = propiedad.fotos_web;

    let restofotos = [];

    let gallery = []

    if (fotos.length < 5) {
        for (let i = fotos.length; i < 5; i++) {
            restofotos = restofotos.concat(
                <li key={i}>
                    <button>
                        <img src="/images/web/bedloop-nofoto.png"></img>
                    </button>
                </li>
            );

            const image = {
                original: "/images/web/bedloop-nofoto.png"
            }
            gallery = gallery.concat(image);
        }
    }

    for (let i = 0; i < fotos.length; i++) {
        const image = {
            original: ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + fotos[i].nombre
        }
        gallery = gallery.concat(image);
    }

    {/* **************** */ }

    const [showGallery, setShowGallery] = useState(false);

    const [showGalleryImage, setShowGalleryImage] = useState(false);

    const [currentImage, setCurrentImage] = useState(0);

    {/* **************** */ }

    const handleShowGallery = () => {
        setShowGallery(!showGallery)
        setFullscreen(true);
    }

    const handleShowGalleryImage = (index) => {
        setCurrentImage(index);
        setShowGalleryImage(!showGalleryImage);
        setFullscreen(true);
    }

    const renderImage = () => {
        return <Image className="fitxaimagegran" src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + fotos[currentImage].nombre} />;
    };

    return (
        <>
            <section className="photo_grid photoGrid_sizeLarge mt25">
                <div className="container">
                    <div className="inner">
                        <ul className="photo_grid-wrapper">
                            {
                                fotos.length > 0 ?
                                    fotos.length >= 5 ?
                                        <>
                                            {fotos.slice(0, 5).map((unafoto, index) => (
                                                <li key={index}>
                                                    <button>
                                                        <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre} onClick={() => handleShowGalleryImage(index)}></img>
                                                    </button>
                                                </li>
                                            ))}
                                            {fotos.map((unafoto, index) => (
                                                index > 4 ?
                                                    <li key={index + "anadido"} className="solofotomobile">
                                                        <button>
                                                            <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre} alt={unafoto.texto_alt ? unafoto.texto_alt[lang] : ""} title={unafoto.texto_title ? unafoto.texto_title[lang] : ""} onClick={() => handleShowGalleryImage(index)}></img>
                                                        </button>
                                                    </li>
                                                    :
                                                    ""
                                            ))}
                                        </>
                                        :
                                        <>
                                            {fotos.map((unafoto, index) => (
                                                <li key={index}>
                                                    <button>
                                                        <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre} onClick={() => handleShowGalleryImage(index)}></img>
                                                    </button>
                                                </li>
                                            ))}

                                            {restofotos}

                                        </>
                                    :
                                    <>
                                        {restofotos}
                                    </>
                            }

                            <li className="indicators"><i className="active"></i></li>
                        </ul>

                        <div className="photo_grid-more solofotopc" onClick={() => handleShowGallery()}>
                            <button><span>{t("Ver todas las imágenes")}</span></button>
                        </div>


                    </div>
                </div>
            </section>

            <Modal
                show={showGallery}
                onHide={() => setShowGallery(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="background_black">
                    <ImageGallery
                        items={gallery}
                        showNav={true}
                        showThumbnails={false}
                        lazyLoad={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        additionalClass="background_black"
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showGalleryImage}
                onHide={() => setShowGalleryImage(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="background_black">
                    {renderImage()}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default BannerPropiedad;
